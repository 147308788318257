import { styled } from '@defa/defa-component-library';

export const Image = styled.img`
    max-width: 100%;
    margin-right: ${({ theme }) => theme.spacing(4)}; ;
`;

export const Content = styled.div`
    height: 321px;
    align-items: stretch;
    display: flex;
    overflow: hidden;
`;

export const First = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    background-color: #32325a;
`;

export const Second = styled.div`
    width: 340px;
    padding: ${({ theme }) => theme.spacing(4)};
`;

export const SecondContentContainer = styled.div`
    margin: ${({ theme }) => theme.spacing(8)} ${({ theme }) => theme.spacing(6)}
        ${({ theme }) => theme.spacing(10)} ${({ theme }) => theme.spacing(4)};
    & > * {
        margin-bottom: ${({ theme }) => theme.spacing(2)} !important;
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
`;

export const SecondButtonContainer = styled.div`
    margin: ${({ theme }) => theme.spacing(4)};
    & > * {
        margin-bottom: ${({ theme }) => theme.spacing(2)} !important;
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
`;
