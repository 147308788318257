import { gql } from '@apollo/client';

export const GET_CHARGE_SYSTEM = gql`
    query GetChargeSystem($id: ID!) {
        chargeSystem(id: $id) {
            id
            groups {
                id
                name
                connectors {
                    id
                    alias
                }
                privateChargingSettings {
                    billingMethod
                    tariff {
                        type
                        fixedCost
                        pricePerKwh
                        pricePerHour
                        hourDivisor
                    }
                }
                privateCharging
            }
            connectors {
                id
                alias
            }
        }
    }
`;

export const UPDATE_CHARGE_SYSTEM = gql`
    mutation UpdateChargeSystem(
        $id: ID!
        $groupId: ID!
        $privateCharging: ChargingTypeState
        $billingMethod: BillingMethod
        $tariff: TariffInput
    ) {
        chargeSystem(id: $id) {
            group(id: $groupId) {
                update(
                    input: {
                        privateChargingSettings: { billingMethod: $billingMethod, tariff: $tariff }
                        privateCharging: $privateCharging
                    }
                ) {
                    privateCharging
                }
            }
        }
    }
`;
