import { gql } from '@apollo/client';

export const GET_CHARGE_SYSTEM = gql`
    query GetChargeSystem($id: ID!) {
        chargeSystem(id: $id) {
            id
            groups {
                id
                name
                connectors {
                    id
                    alias
                }
                publicChargingSettings {
                    durationLimit
                    schema {
                        day
                        to
                        from
                        isAllDay
                    }
                    tariff {
                        type
                        fixedCost
                        pricePerKwh
                        pricePerHour
                        hourDivisor
                    }
                }
                publicCharging
                broadcast
                visibleInApps
            }
            connectors {
                id
                alias
            }
        }
    }
`;

export const UPDATE_CHARGE_SYSTEM = gql`
    mutation UpdateChargeSystem(
        $id: ID!
        $groupId: ID!
        $durationLimit: Float
        $publicCharging: ChargingTypeState
        $broadcast: Boolean
        $visibleInApps: Boolean
        $tariff: TariffInput
        $schema: [PublicChargingTimeInput]
    ) {
        chargeSystem(id: $id) {
            group(id: $groupId) {
                update(
                    input: {
                        publicChargingSettings: {
                            durationLimit: $durationLimit
                            schema: $schema
                            tariff: $tariff
                        }
                        publicCharging: $publicCharging
                        broadcast: $broadcast
                        visibleInApps: $visibleInApps
                    }
                ) {
                    publicCharging
                }
            }
        }
    }
`;
