import React, { useState } from 'react';

import {
    Button,
    Composite,
    Heading,
    Level,
    ProgressIndicator,
    ProgressType,
    Size,
    Variant,
} from '@defa/defa-component-library';
import ReactMarkdown from 'react-markdown';
import {
    Content,
    First,
    Second,
    SecondContentContainer,
    SecondButtonContainer,
    Image,
} from './stripe-modal.styles';
import { logos } from '../../assets/logos';
import { STRIPE_TEXT_INDEX } from '../../constants';
import i18n from '../../i18n';
import { UIState } from '../../models/ui';

interface StripeModalProps {
    onClick: () => void;
    onClose: () => void;
}

export const StripeModal = ({ onClick, onClose }: StripeModalProps) => {
    const [uiState, setUiState] = useState<UIState>(UIState.READY);
    const onClickHandler = () => {
        setUiState(UIState.LOADING);
        onClick();
    };
    return (
        <Content>
            <Composite align="stretch">
                <First>
                    <Image src={logos[STRIPE_TEXT_INDEX]} />
                </First>
                <Second>
                    <Composite justify="end">
                        <Button
                            name="cancel-button"
                            icon="close"
                            fillParent={false}
                            variant={Variant.TERTIARY}
                            size={Size.TINY}
                            onClick={onClose}
                        />
                    </Composite>
                    <SecondContentContainer>
                        <Heading level={Level.h3}>
                            {i18n.t('PaymentMethodSettings.Stripe.ModalHeader')}
                        </Heading>
                        <ReactMarkdown>
                            {i18n.t('PaymentMethodSettings.Stripe.ModalDescription')}
                        </ReactMarkdown>
                    </SecondContentContainer>
                    <SecondButtonContainer>
                        {uiState === UIState.LOADING ? (
                            <ProgressIndicator type={ProgressType.DONUT_LOADING} progress={50} />
                        ) : (
                            <Button
                                name="continue-button"
                                text={i18n.t('PaymentMethodSettings.Stripe.ModalButtonText')}
                                iconAfter="chevronRight"
                                iconUseMargin
                                fillParent={false}
                                onClick={onClickHandler}
                            />
                        )}
                    </SecondButtonContainer>
                </Second>
            </Composite>
        </Content>
    );
};
