import React, { useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Group, Heading, Level, Variant } from '@defa/defa-component-library';
import { useHistory, useParams } from 'react-router-dom';
import { SetupPublicSettings } from '../../../../fractions/setup-public-settings';
import i18n from '../../../../i18n';
import { ChargingTypeState, PublicChargingTime, Tariff } from '../../../../models';
import { GET_CHARGE_SYSTEM, UPDATE_CHARGE_SYSTEM } from './public-settings-same-settings.queries';
import { FeatureToggle, UserHasFeature } from '../../../../utils/feature';
import { useCurrency } from '../../../../utils/hooks';
import { DEFAULT_TARIFF } from '../../../../constants';

export function PublicSettingsSameSettings() {
    const history = useHistory();
    const [currency] = useCurrency();
    const [hasPublicBroadcastingFeature] = UserHasFeature(FeatureToggle.PublicBroadcasting);
    const [hasPublicAvailableFeature] = UserHasFeature(FeatureToggle.PublicAvailable);

    const [tariff, setTariff] = useState<Tariff>(DEFAULT_TARIFF);
    const [hasChargingFee, setChargingFee] = useState<boolean>(false);

    const [durationLimit, setDurationLimit] = useState<string>('');
    const [hasDurationLimit, setHasDurationLimit] = useState<string>('');
    const [hasPubliclyAvailable, setHasPubliclyAvailable] = useState<string>('');
    const [hasVisibility, setHasVisibility] = useState<string>('');
    const [hasBroadcast, setHasBroadcast] = useState<string>('');
    const [daySettings, setDaySettings] = useState<Array<PublicChargingTime>>([]);
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const { id } = useParams<{ id: string }>();

    const { data = { chargeSystem: {} } } = useQuery(GET_CHARGE_SYSTEM, {
        variables: { id },
        fetchPolicy: 'network-only',
    });

    const [updateChargeSystem] = useMutation(UPDATE_CHARGE_SYSTEM);

    const onHasDurationLimitChanged = (newHasDurationLimit: string) => {
        setHasDurationLimit(newHasDurationLimit);
    };

    const onDurationLimitChanged = (newDurationLimit: string) => {
        setDurationLimit(newDurationLimit);
    };

    const onHasPubliclyAvailableChanged = (newHasPubliclyAvailable: string) => {
        if (hasPubliclyAvailable === 'ALWAYS' || daySettings.length === 0) {
            const allDaySettings = days.map((day: string) => ({ day, isAllDay: true }));
            setDaySettings(allDaySettings);
        }
        setHasPubliclyAvailable(newHasPubliclyAvailable);
    };

    const onHasVisibilityChanged = (newHasVisibility: string) => {
        setHasVisibility(newHasVisibility);
    };
    const onHasBroadcastChanged = (newHasBroadcast: string) => {
        setHasBroadcast(newHasBroadcast);
    };

    const tariffChanged = (newTariff: Tariff) => {
        setTariff(newTariff);
    };

    const chargingFeeChanged = (chargingFee: boolean) => {
        setChargingFee(!chargingFee);
    };

    const onSubmitClick = useCallback(() => {
        const schema =
            hasPubliclyAvailable === 'SPECIFIC'
                ? daySettings.map(({ day, to, from, isAllDay }) => ({
                      day,
                      from,
                      to,
                      isAllDay,
                  }))
                : [];
        updateChargeSystem({
            variables: {
                id,
                groups: data.chargeSystem.groups.map((g: any) => ({
                    id: g.id,
                    publicChargingSettings: {
                        durationLimit: durationLimit ? parseFloat(durationLimit) : 0,
                        schema,
                        tariff: hasChargingFee ? tariff : DEFAULT_TARIFF,
                    },
                    publicCharging: ChargingTypeState.ENABLED,
                    broadcast: hasBroadcast === 'YES',
                    visibleInApps: hasVisibility === 'YES',
                })),
            },
        }).then(() => {
            history.replace(`/charge-system/${id}/setup/private`);
        });
    }, [
        updateChargeSystem,
        id,
        data,
        durationLimit,
        hasPubliclyAvailable,
        daySettings,
        hasBroadcast,
        hasVisibility,
        history,
        tariff,
        hasChargingFee,
    ]);
    return (
        <Group maxWidth="640px">
            <Group>
                <Heading level={Level.h1}>{i18n.t('PublicSettingsSameSettings.Header')}</Heading>
            </Group>
            <SetupPublicSettings
                tariff={tariff}
                tariffChanged={tariffChanged}
                chargingFeeChanged={chargingFeeChanged}
                hasChargingFee={hasChargingFee}
                hasDurationLimit={hasDurationLimit}
                onHasDurationLimitChanged={onHasDurationLimitChanged}
                durationLimit={durationLimit}
                onDurationLimitChanged={onDurationLimitChanged}
                hasPubliclyAvailable={hasPubliclyAvailable}
                onHasPubliclyAvailableChanged={onHasPubliclyAvailableChanged}
                hasVisibility={hasVisibility}
                onHasVisibilityChanged={onHasVisibilityChanged}
                hasBroadcast={hasBroadcast}
                onHasBroadcastChanged={onHasBroadcastChanged}
                days={days}
                daySettings={daySettings}
                setDaySettings={setDaySettings}
                currency={currency}
            />
            <Button
                variant={Variant.PRIMARY}
                disabled={
                    (hasChargingFee &&
                        tariff.pricePerHour &&
                        tariff.pricePerKwh &&
                        parseFloat(tariff.pricePerHour) <= 0 &&
                        parseFloat(tariff.pricePerKwh) <= 0) ||
                    hasDurationLimit.length < 1 ||
                    (hasDurationLimit === 'YES' && durationLimit.length < 1) ||
                    (hasPubliclyAvailable.length < 1 && hasPublicAvailableFeature) ||
                    hasVisibility.length < 1 ||
                    (hasBroadcast.length < 1 && hasPublicBroadcastingFeature)
                }
                onClick={onSubmitClick}
            >
                {i18n.t('PublicSettingsSameSettings.Continue')}
            </Button>
        </Group>
    );
}
