import React, { useCallback, useState } from 'react';
import {
    Accordion,
    AccordionItem,
    Composite,
    Currency,
    Group,
    Heading,
    Level,
    RadioList,
    RadioType,
    Switch,
    Text,
    TextInput,
    TextInputMode,
    TextType,
    Unit,
    useTheme,
} from '@defa/defa-component-library';
import i18n from '../../i18n';
import { PublicChargingTime } from '../../models/group';
import { WeeklyTimeSection } from '../weekly-time-section';
import { Feature, FeatureToggle } from '../../utils/feature';
import { useStripeStatus } from '../../utils/hooks';
import { PriceModel, StripeStatus, Tariff } from '../../models';
import { formatNumberToLocale, parseLocaleFormattedNumber } from '../../utils/math';

export function SetupPublicSettings({
    hasDurationLimit,
    onHasDurationLimitChanged,
    durationLimit,
    onDurationLimitChanged,
    hasPubliclyAvailable,
    onHasPubliclyAvailableChanged,
    hasVisibility,
    onHasVisibilityChanged,
    hasBroadcast,
    onHasBroadcastChanged,
    days,
    daySettings,
    setDaySettings,
    currency,
    tariff,
    tariffChanged,
    chargingFeeChanged,
    hasChargingFee,
}: {
    hasDurationLimit: string;
    onHasDurationLimitChanged: (newHasDurationLimit: string) => void;
    durationLimit: string;
    onDurationLimitChanged: (newDurationLimit: string) => void;
    hasPubliclyAvailable: string;
    onHasPubliclyAvailableChanged: (newHasPubliclyAvailable: string) => void;
    hasVisibility: string;
    onHasVisibilityChanged: (newHasVisibility: string) => void;
    hasBroadcast: string;
    onHasBroadcastChanged: (newHasBroadcast: string) => void;
    days: string[];
    daySettings: Array<PublicChargingTime>;
    setDaySettings: (newDaySettings: PublicChargingTime[]) => void;
    currency: Currency;
    tariff: Tariff;
    tariffChanged: (newTariff: Tariff) => void;
    chargingFeeChanged: (newChargingFee: boolean) => void;
    hasChargingFee: boolean;
}) {
    const [stripeStatus] = useStripeStatus();
    const theme = useTheme();

    const stripeStatusDisabled = stripeStatus === StripeStatus.DISABLED;

    const [activeTariffTypes, setActiveTariffTypes] = useState<string[]>([]);
    const [powerPrice, setPowerPrice] = useState(
        formatNumberToLocale(parseFloat(tariff.pricePerKwh ?? '0'), navigator.language)
    );
    const [timePrice, setTimePrice] = useState(
        formatNumberToLocale(parseFloat(tariff.pricePerHour ?? '0'), navigator.language)
    );

    const onPowerPriceChanged = (newPowerPrice: string) => {
        setPowerPrice(newPowerPrice);
        tariffChanged({
            ...tariff,
            pricePerKwh: parseLocaleFormattedNumber(newPowerPrice, navigator.language),
        });
    };
    const onTimePriceChanged = (newTimePrice: string) => {
        setTimePrice(newTimePrice);
        tariffChanged({
            ...tariff,
            pricePerHour: parseLocaleFormattedNumber(newTimePrice, navigator.language),
        });
    };

    const updateChargeFee = useCallback(
        (useChargingFee) => {
            chargingFeeChanged(!useChargingFee);
        },
        [chargingFeeChanged]
    );

    const onPriceChange = (value: string) => {
        if (activeTariffTypes.includes(value)) {
            switch (value) {
                case PriceModel.timeUsage:
                    onTimePriceChanged('0');
                    break;
                case PriceModel.powerUsage:
                    onPowerPriceChanged('0');
                    break;
                default:
                    break;
            }
            setActiveTariffTypes((currentTariffTypes) =>
                currentTariffTypes.filter((item) => item !== value)
            );
        } else {
            setActiveTariffTypes([...activeTariffTypes, value]);
        }
    };

    return (
        <>
            <Group divider>
                <Heading level={Level.h4}>
                    {i18n.t('SetupPublicSettings.ChargingFee.Header')}
                </Heading>
                {stripeStatusDisabled && (
                    <Group>
                        <Heading level={Level.h4} color={theme.inputMessageColorInfo}>
                            {i18n.t('Stripe.ConfigurationMissing')}
                        </Heading>
                    </Group>
                )}

                <Composite>
                    <Heading level={Level.h4}>
                        {i18n.t('GroupSettings.Private.ChargingFee')}
                    </Heading>
                    <Switch
                        name="charging-fee"
                        checked={hasChargingFee}
                        onChange={updateChargeFee}
                        disabled={stripeStatusDisabled}
                    />
                </Composite>
                {hasChargingFee && (
                    <Accordion selected={activeTariffTypes} onChange={onPriceChange} multiple>
                        <AccordionItem
                            icon="clock"
                            title={i18n.t('RentalFee.TimeOptionHeader')}
                            description={i18n.t('RentalFee.TimeOptionDescription')}
                            value={PriceModel.timeUsage}
                        >
                            <TextInput
                                name="rental-fee-hours"
                                mode={TextInputMode.CURRENCY}
                                locale={navigator.language}
                                currency={currency}
                                unit={Unit.HOUR}
                                label={i18n.t('RentalFee.TimeInputLabel')}
                                value={timePrice}
                                onChange={onTimePriceChanged}
                            />
                        </AccordionItem>
                        <AccordionItem
                            icon="dashboard"
                            title={i18n.t('RentalFee.PowerUsageOptionHeader')}
                            description={i18n.t('RentalFee.PowerUsageOptionDescription')}
                            value={PriceModel.powerUsage}
                        >
                            <TextInput
                                name="rental-fee-hours"
                                mode={TextInputMode.CURRENCY}
                                locale={navigator.language}
                                currency={currency}
                                unit={Unit.KILOWATTHOUR}
                                label={i18n.t('RentalFee.PowerUsageInputLabel')}
                                value={powerPrice}
                                onChange={onPowerPriceChanged}
                            />
                        </AccordionItem>
                    </Accordion>
                )}
            </Group>
            <Group divider>
                <Heading level={Level.h4}>{i18n.t('SetupPublicSettings.Duration.Header')}</Heading>
                <Text color={theme.descriptionColor} type={TextType.descriptionSmall}>
                    {i18n.t('DurationLimit.PublicDescription')}
                </Text>
                <RadioList
                    name="has-duration"
                    defaultChecked={hasDurationLimit}
                    onChange={onHasDurationLimitChanged}
                    type={RadioType.BOX}
                    items={[
                        {
                            value: 'YES',
                            label: i18n.t('SetupPublicSettings.Duration.Yes'),
                        },
                        {
                            value: 'NO',
                            label: i18n.t('SetupPublicSettings.Duration.No'),
                        },
                    ]}
                />
                {hasDurationLimit === 'YES' && (
                    <TextInput
                        name="duration-limit"
                        label={i18n.t('DurationLimit.InputLabel')}
                        mode={TextInputMode.NUMBER}
                        value={durationLimit}
                        onChange={onDurationLimitChanged}
                    />
                )}
            </Group>
            <Feature feature={FeatureToggle.PublicAvailable}>
                <Group divider>
                    <Heading level={Level.h4}>
                        {i18n.t('SetupPublicSettings.PubliclyAvailable.Header')}
                    </Heading>
                    <RadioList
                        name="publicly-available"
                        defaultChecked={hasPubliclyAvailable}
                        onChange={onHasPubliclyAvailableChanged}
                        type={RadioType.BOX}
                        items={[
                            {
                                value: 'ALWAYS',
                                label: i18n.t('SetupPublicSettings.PubliclyAvailable.AlwaysLabel'),
                                description: i18n.t(
                                    'SetupPublicSettings.PubliclyAvailable.AlwaysDescription'
                                ),
                            },
                            {
                                value: 'SPECIFIC',
                                label: i18n.t(
                                    'SetupPublicSettings.PubliclyAvailable.SpecificLabel'
                                ),
                            },
                        ]}
                    />
                    {hasPubliclyAvailable === 'SPECIFIC' && (
                        <WeeklyTimeSection
                            days={days}
                            daySettings={daySettings}
                            setDaySettings={setDaySettings}
                        />
                    )}
                </Group>
            </Feature>
            <Group divider>
                <Heading level={Level.h4}>
                    {i18n.t('SetupPublicSettings.Visibility.Header')}
                </Heading>
                <Text color={theme.descriptionColor} type={TextType.descriptionSmall}>
                    {i18n.t('SetupPublicSettings.Visibility.Description')}
                </Text>
                <RadioList
                    name="has-visibility"
                    defaultChecked={hasVisibility}
                    onChange={onHasVisibilityChanged}
                    type={RadioType.BOX}
                    items={[
                        {
                            value: 'YES',
                            label: i18n.t('SetupPublicSettings.Visibility.Yes'),
                        },
                        {
                            value: 'NO',
                            label: i18n.t('SetupPublicSettings.Visibility.No'),
                        },
                    ]}
                />
            </Group>
            <Feature feature={FeatureToggle.PublicBroadcasting}>
                <Group>
                    <Heading level={Level.h4}>
                        {i18n.t('SetupPublicSettings.Broadcast.Header')}
                    </Heading>
                    <Text color={theme.descriptionColor} type={TextType.descriptionSmall}>
                        {i18n.t('SetupPublicSettings.Broadcast.Description')}
                    </Text>
                    <RadioList
                        name="has-broadcast"
                        defaultChecked={hasBroadcast}
                        onChange={onHasBroadcastChanged}
                        type={RadioType.BOX}
                        items={[
                            {
                                value: 'YES',
                                label: i18n.t('SetupPublicSettings.Broadcast.Yes'),
                            },
                            {
                                value: 'NO',
                                label: i18n.t('SetupPublicSettings.Broadcast.No'),
                            },
                        ]}
                    />
                </Group>
            </Feature>
        </>
    );
}
