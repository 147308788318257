import { gql } from '@apollo/client';
import { ChargeSystem, ResetResponseCode } from '../../models';

export interface ChargeSystemReponseType {
    chargeSystem: ChargeSystem;
}

export interface UpdateChargeSystemReponseType {
    chargeSystem: {
        group: {
            update: {
                broadcast: boolean;
                publicCharging: string;
                responseCodes: ResetResponseCode[];
                visibleInApps: boolean;
                publicChargingSettings: {
                    durationLimit: number;
                    priceModel: string;
                    priceModelAmount: number | null;
                };
            };
        };
    };
}

export const GET_CHARGE_SYSTEM = gql`
    query GetChargeSystem($id: ID!, $groupId: ID!) {
        chargeSystem(id: $id) {
            id
            group(id: $groupId) {
                publicChargingSettings {
                    durationLimit
                    schema {
                        day
                        to
                        from
                        isAllDay
                    }
                    tariff {
                        type
                        fixedCost
                        pricePerKwh
                        pricePerHour
                        hourDivisor
                    }
                }
                publicCharging
                broadcast
                visibleInApps
            }
        }
    }
`;

export const UPDATE_CHARGE_SYSTEM = gql`
    mutation UpdateChargeSystem(
        $id: ID!
        $groupId: ID!
        $durationLimit: Float!
        $publicCharging: ChargingTypeState
        $tariff: TariffInput
        $broadcast: Boolean
        $visibleInApps: Boolean
        $schema: [PublicChargingTimeInput]
    ) {
        chargeSystem(id: $id) {
            group(id: $groupId) {
                update(
                    input: {
                        publicChargingSettings: {
                            durationLimit: $durationLimit
                            schema: $schema
                            tariff: $tariff
                        }
                        publicCharging: $publicCharging
                        broadcast: $broadcast
                        visibleInApps: $visibleInApps
                    }
                ) {
                    publicChargingSettings {
                        durationLimit
                        schema {
                            day
                            to
                            from
                            isAllDay
                        }
                        tariff {
                            type
                            fixedCost
                            pricePerKwh
                            pricePerHour
                            hourDivisor
                        }
                    }
                    publicCharging
                    broadcast
                    visibleInApps
                    responseCodes
                }
            }
        }
    }
`;
